/*-----------------------
    Header Styles  
-------------------------*/
.header-brand {
    a {
        display: block;
        img {
            @media only screen and (max-width: 991px) {
               max-height: 35px;
            }
            @media only screen and (max-width: 320px) {
               max-height: 30px;
            }
        }
    }
}

.logo-light {
    display: none !important;
}

.axil-mainmenu {
    .header-navbar {
        display: flex;
        align-items: center;
        width: 100%;
        .header-main-nav {
            flex: 1;
            margin: 0 50px;
            @media only screen and (max-width: 991px) {
                margin: 0;
            }
        }
    }
    &.axil-sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 5;
        background-color: var(--color-white);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
        transition: var(--transition);
        .mainmenu {
            >li {
                >a {
                    height: 80px;
                    line-height: 80px;
                    &:before {
                        bottom: 20px;
                    }
                }
            }
        }
    }
}

.header-style-1 {
    background-color: #f9f3f0;
    padding-bottom: 30px;
    .header-navbar {
        background-color: var(--color-white);
        padding: 0 40px;
        border-radius: 10px;
        @media #{$smlg-device} {
            padding: 0 25px;
        }
        @media #{$md-layout} {
            padding: 15px 25px;
        }
        @media #{$sm-layout} {
            padding: 15px 15px;
        }
    }
    .mainmenu > li {
        @media #{$smlg-device} {
            margin: 0 13px;
        }
    }
    .axil-mainmenu {
        &.axil-sticky {
            background-color: transparent;
            box-shadow: none;
            padding-top: 10px;
            .header-navbar {
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.10);
            }
        }
    }
    .header-brand {
        @media only screen and (max-width: 575px) {
           margin-right: 10px;
        }
    }
}

.header-style-3 {
    background-color: #f9f3f0;
    .axil-mainmenu {
        padding: 20px 0;
        position: relative;
        z-index: 2;
        &.axil-sticky {
            position: fixed;
            background-color: transparent;
            box-shadow: none;
            z-index: 4;
            .header-navbar {
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .10);
            }
        }
    }
    .header-navbar {
        background-color: var(--color-white);
        padding: 0 20px;
        border-radius: 10px;
        @media #{$md-layout} {
            padding: 10px 20px;
        }
        @media #{$sm-layout} {
            padding: 10px 20px;
        }
    }
    .header-action {
        display: flex;
        align-items: center;
        .header-btn {
            .axil-btn {
                padding: 12px 30px;
                font-size: 15px;
                font-weight: 500;
                @media #{$small-mobile} {
                    padding: 12px 20px;
                }
            }
        }
    }
}

.header-style-4 {
    .mainmenu {
        >li {
            >a {
                line-height: 60px;
                height: 60px;
                &:before {
                    bottom: 18px;
                }
            }
        }
    }
}

.header-style-5 {
    .axil-mainmenu {
        @media only screen and (max-width: 991px) {
            padding: 15px 0;
        }
    }
    
    @media only screen and (max-width: 1399px) {
        .header-navbar {
            .header-main-nav {
                margin: 0 20px;
                @media only screen and (max-width: 991px) {
                    margin: 0;
                }
            }
        }
        .mainmenu {
            margin: 0 -18px;
            >li {
                margin: 0 18px;
            }
        }
    }

    .header-action {
        .axil-search {
            input {
                border: 1px solid #f0f0f0;
                height: 40px;
            }
        }
    }
}

.header-style-7 {
    position: relative;
    z-index: 5;
    .axil-mainmenu {
        padding: 20px 45px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.03));
        @media (max-width: 991px) {
            padding: 20px 0;
            filter: none;
        }
        .header-navbar {
            .header-main-nav {
                margin-left: 90px;
                @media (max-width: 1199px) {
                    margin: 0 30px;
                }
                @media (max-width: 991px) {
                    margin: 0;
                }
                
            }
        }
    }
    .header-action {
        >ul {
            margin: 0 -14px;
            @media (max-width: 767px) {
                margin: 0 -10px;
            }
            >li {
                margin: 0 14px;
                @media (max-width: 767px) {
                    margin: 0 10px;
                }
                >a {
                    font-size: 19px;
                }
            }
        }
        .axil-search {
            margin-right: 110px;
            @media (max-width: 1599px) {
                margin-right: 16px;
            }
            @media (max-width: 767px) {
                margin-right: 10px;
            }
            input {
                border: 1px solid #D6D6D6;
                height: 40px;
                border-radius: 8px;
                padding: 0 15px;
                padding-right: 35px;
                width: 448px;
                max-width: 448px;
            }
            .icon {
                left: auto;
                right: 16px;
                font-size: 18px;
                color: var(--color-body);
                top: 50%;
                &:hover {
                    color: var(--color-heading);
                }
            }
        }
        .shopping-cart {
            .cart-dropdown-btn {
                .cart-count {
                    height: 14px;
                    width: 14px;
                    line-height: 14px;
                    font-size: 10px;
                    border: none;
                    display: block;
                    top: -16px;
                    right: -8px;
                }
            }
        }
        .my-account {
            .my-account-dropdown {
                margin-top: 30px;
            }
        }
    }

    .mainmenu {
        justify-content: flex-start;
        margin: 0 -20px;
        @media (max-width: 1199px) {
            margin: 0 -15px;
        }
        >li {
            margin: 0 20px;
            @media (max-width: 1199px) {
                margin: 0 15px;
            }
            >a {
                font-size: 16px;
                font-weight: 500;
                line-height: 1 !important;
                height: auto !important;
                i {
                    margin-right: 10px;
                    color: var(--color-body);
                    transition: .3s;
                }
                &:before {
                    display: none;
                }
                &:hover {
                    i {
                        color: var(--color-heading);
                    }
                }
            }
            &.dropdown {
                .dropdown-toggle {
                    border: 1px dashed var(--color-primary);
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    padding: 9px 14px;
                    border-radius: 8px;
                    i {
                        color: var(--color-primary);

                    }
                    &:after {
                        content: "\f063";
                        font-family: var(--font-awesome);
                        border: none;
                        vertical-align: middle;
                        font-size: 12px;
                        margin-left: 8px;
                        transition: all 0.3s ease-in-out;
                    }
                    &:hover {
                        color: var(--color-primary);
                        i {
                            color: var(--color-primary);
                        }
                        &:after {
                            color: var(--color-primary);
                        }
                    }
                    @media (max-width: 991px) {
                        border: none;
                        padding: 5px 0;
                        i {
                            color: var(--color-body);
                        }
                    }
                }
                .dropdown-menu {
                    list-style: none;
                    @media (min-width: 992px) {
                        transform: translate(0px, 20px) !important;
                        inset: initial !important;
                        background: #ffffff;
                        min-width: 250px;
                        padding: 15px 10px;
                        border-radius: 4px;
                        display: block !important;
                        visibility: hidden;
                        opacity: 0;
                        box-shadow: var(--shadow-primary);
                        transition: all 0.3s ease-in-out;
                    }
                    @media (max-width: 991px) {
                        position: initial !important;
                        border: none;
                        padding: 0 0 0 10px;
                    }
                    li {
                        margin: 0;
                        a {
                            position: relative;
                            font-size: 15px;
                            text-transform: capitalize;
                            color: var(--color-heading);
                            font-weight: 500;
                            padding: 10px 15px;
                            display: block;
                            transition: all 0.3s ease-in-out;
                            z-index: 1;
                            overflow: hidden;
                            border-bottom: 1px solid #f3f3f3;
                            &:hover {
                                color: var(--color-secondary);
                                background: transparent;
                            }
                            &.active {
                                color: var(--color-secondary);
                                &:hover {
                                    color: var(--color-secondary);
                                }
                            }
                            @media (max-width: 991px) {
                                padding: 10px 0;
                            }
                        }
                        &:last-child {
                            a {
                                border-bottom: none;
                            }
                        }
                    }
                    &.show {
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0px, 10px) !important;
                    }
                }
            }
        }
    }
}

.d-none-laptop {
    @media (max-width: 1599px) {
        display: none;
    }
}

.d-none-desktop {
    @media (min-width: 1600px) {
        display: none;
    }
}

/*-----------------------
    Header Action  
-------------------------*/
.header-action {
    @media #{$small-mobile} {
        margin-top: 4px;
    }
    >ul {
        display: flex;
        align-items: center;
        margin: 0 -10px;
        padding: 0;
        @media #{$small-mobile} {
            margin: 0 -6px;
        }
        >li {
            @extend %liststyle;
            margin: 0 10px;
            @media #{$small-mobile} {
                margin: 0 5px;
            }
            >a {
                font-size: 24px;
                font-weight: 500;
                color: var(--color-heading);
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;
                @media #{$small-mobile} {
                    font-size: 22px;
                }
                >i {
                    display: inline-block;
                    line-height: 0;
                }
                &::after {
                    content: "";
                    height: 45px;
                    width: 45px;
                    background-color: var(--color-secondary);
                    transform: scale(0);
                    border-radius: 50%;
                    position: absolute;
                    z-index: -1;
                    transition: var(--transition);
                    @media #{$large-mobile} {
                        height: 35px;
                        width: 35px;
                    }
                }
                &:focus {
                    color: var(--color-heading);
                }
                &:hover {
                    color: var(--color-white);
                    &::after {
                        transform: scale(1);
                    }
                }
                &.open {
                    color: var(--color-white);
                    &::after {
                        transform: scale(1);
                    }
                }
            }
        }
    }
    .shopping-cart {
        .cart-dropdown-btn {
            .cart-count {
                text-align: center;
                background-color: var(--color-primary);
                border: 2px solid var(--color-white);
                font-size: 12px;
                font-weight: 500;
                color: var(--color-white);
                border-radius: 50%;
                height: 22px;
                width: 22px;
                line-height: 19px;
                position: absolute;
                top: -12px;
                right: -12px;
            }
        }
    }
    .my-account {
        position: relative;
        .my-account-dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            background: #ffffff;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            min-width: 250px;
            padding: 20px;
            border-radius: 4px;
            box-shadow: var(--shadow-primary);
            @extend %transition;
            list-style: none;
            transform: translateY(30px);
            margin-top: 20px;
            @media #{$small-mobile} {
                right: -30px;
            }
            ul {
                list-style: none;
                padding-left: 0;
            }
            .title {
                font-size: 12px;
                font-weight: 500;
            }
            li {
                margin: 0;
                a {
                    font-size: 16px;
                    border-bottom: 1px solid #eeeeee;
                    padding: 12px 0;
                    display: block;
                }
                &:hover {
                    >a {
                        color: var(--color-primary);
                    }
                }
            }
            .login-btn {
                text-align: center;
                text-align: center;
                margin-top: 30px;
                margin-bottom: 25px;
            }
            .axil-btn {
               padding: 10px 35px;
               width: 100%;
            }
            .reg-footer {
                font-size: 12px;
                .btn-link {
                    margin-left: 7px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: var(--color-dark);
                    position: relative;
                    line-height: 1;
                    border-bottom: 2px solid #999FAE;
                    text-decoration: none;
                    &:hover {
                        color: var(--color-primary);
                        border-color: var(--color-primary);
                    }
                }
            }
            
          
            &.open {
                opacity: 1;
                visibility: visible;
                z-index: 9;
                transform: translateY(0);
            }
        }
    }
    .axil-search {
        position: relative;
        .icon {
            position: absolute;
            left: 15px;
            width: auto;
            padding: 0;
            top: 52%;
            transform: translateY(-50%);
            line-height: 1;
            background-color: transparent;
            font-size: 22px;
            color: var(--color-heading);
            i {
                display: inline-block;
                line-height: 0;
            }
        }
        input {
            background: var(--color-white);
            color: var(--color-heading);
            border-radius: 6px;
            padding-left: 40px;
            padding-right: 10px;
            max-width: 250px;
            height: 50px;
            opacity: 1;
            font-size: 14px;
        }
    }
}


/*-----------------------
    Header Search
-------------------------*/
.header-search-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) scale(.8);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    @media only screen and (max-width: 991px) {
        width: 92%; 
        right: -100%;
    }
    .card-close {
        height: 40px;
        width: 40px;
        font-size: 16px;
        color: var(--color_black);
        border-radius: 50%;
        @extend %transition;
        position: absolute;
        right: -60px;
        top: 0;
        z-index: 1;
        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
        @media only screen and (max-width: 991px) {
            height: 35px;
            width: 35px;
            font-size: 15px;
            right: 30px;
            top: 12px;
        }
        @media #{$small-mobile} {
            right: 15px;
            top: 14px;
        }
    }
    .header-search-wrap {
        background-color: var(--color-white);
        border-radius: 10px;
        padding: 40px 30px;
        width: 800px;
        height: 575px;
        max-height: 90vh;
        overflow: auto;
        @media only screen and (max-width: 991px) {
            width: 100%;   
            padding: 70px 30px 30px;
        }
        @media only screen and (max-width: 479px) {  
            padding: 70px 15px 30px;
        }
    }
    .card-header {
        background-color: transparent;
        padding: 0;
        border-bottom: none;
        form {
            padding-bottom: 30px;
        }
        .form-control {
            border: 1px solid #f1f1f1;
            border-radius: 6px !important;
            font-size: 15px;
            height: 55px;
            padding: 5px 20px 5px 50px;
            color: var(--color-);
            &:focus {
                box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.04);
            }
            &::placeholder {
                color: var(--color-heading);
                opacity: 1;
            }
            &:-ms-input-placeholder {
                color: var(--color-heading);
            }
            &::-ms-input-placeholder {
                color: var(--color-heading);
            }
        }
        .axil-btn {
            width: auto;
            padding: 5px 20px;
            font-size: 15px;
            background-color: transparent;
            margin: 5px;
            border-radius: 6px !important;
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 10;
            pointer-events: none;
            
            &:before {
                display: none;
            }
            i {
                margin-right: 0;
                color: var(--color-lightest);
            }
            &:hover {
                i {
                    color: var(--color-heading);
                }
            }
        }
    }
    .card-body {
        padding: 0;
    }
    .search-result-header {
        border-bottom: 1px solid #F6F7FB;
        padding-bottom: 15px;
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 400;
            color: var(--color-heading);
        }
        .view-all {
            font-size: 14px;
            transition: .3s;
            color: var(--color-heading);
            position: relative;
            &:after {
                content: "";
                height: 2px;
                width: 0;
                background-color: var(--color-heading);
                position: absolute;
                bottom: -2px;
                right: 0;
                opacity: 0;
                transition: 0.5s;
            }
            &:hover {
                color: var(--color-heading);
                &:after {
                    width: 100%;
                    opacity: 1;
                    left: 0;
                }
            }
        }
    }
    .psearch-results {
        .axil-product-list {
            padding: 20px;
            margin-bottom: 20px;
            @media #{$large-mobile} {
                display: flex;
                text-align: left;
                align-items: flex-start;
                .thumbnail {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
                .product-rating {
                    justify-content: flex-start;
                }
            }
            @media #{$small-mobile} {
                padding: 15px;
                .thumbnail {
                    width: 60px;
                }
                .product-content {
                    .product-title {
                        margin-bottom: 4px;
                    }
                    .product-price-variant {
                        font-size: 16px;
                    }
                    .rating-icon {
                        margin-right: 10px;
                    }
                    .product-rating {
                        display: block;
                        margin-bottom: 5px;
                        .rating-number {
                            margin-left: 0;
                        }
                    }
                    .product-cart {
                        margin-top: 10px;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.show {
            visibility: visible;
            opacity: 1;
        }
    }
    &.open {
        visibility: visible;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        transition: all .3s cubic-bezier(0.29, 1.39, 0.86, 1.15);
        
    }
}

/*-----------------------
    Header Aside Menu
-------------------------*/
.axil-mainmenu.aside-category-menu {
    background-color: #f7f7f7;
    @media only screen and (max-width: 991px) {
        padding: 10px 0;   
    }
    .header-main-nav {
        margin-right: 0;
        margin-left: 40px;
        @media only screen and (max-width: 991px) {
            margin-left: 0;   
        }
    }
    .header-nav-department {
        width: 250px;
        @media only screen and (max-width: 1199px) {
            width: auto;
        }
    }
    .header-department {
        position: relative;
        .header-department-text {
            font-size: 16px;
            background: var(--color-primary);
            margin-bottom: 0;
            display: flex;
            padding: 17px 30px;
            position: relative;
            @media only screen and (max-width: 991px) {
                padding: 10px 20px;  
                border-radius: 6px;
            }
            .icon {
                margin-right: 20px;
                color: var(--color-white);
                font-size: 18px;
                @media only screen and (max-width: 991px) {
                    margin-right: 15px;   
                }
                @media #{$small-mobile} {
                    display: none;
                }
            }
            .text {
                color: var(--color-white);
                margin: 0;
                cursor: pointer;
            }
        }
        .department-nav-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: var(--color-white);
            border: 1px solid #f3f3f3;
            padding: 6px 0;
            transition: var(--transition);
            z-index: 5;
            @media #{$smlg-device} {
                position: fixed;
                top: 0;
                left: -260px;
                bottom: 0;
                z-index: 50;
                width: 250px;
                padding: 70px 0 20px;
                height: 100%;
                border-radius: 0;
            }
            .sidebar-close {
                font-size: 14px;
                color: var(--color-black);
                position: absolute;
                top: 8px;
                right: 15px;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background-color: var(--color-lighter);
                border-radius: 10px;
                display: none;
                &:hover {
                    background-color: var(--color-primary);
                    color: var(--color-white);
                }
                @media only screen and (max-width: 1199px) {
                    display: block;
                }
            }
            .nav-menu-list {
                @extend %liststyle;
                @media only screen and (max-width: 1199px) {
                    height: 100%;
                    overflow: auto;
                }
            }
            >ul {
                >li {
                    padding: 0 30px;
                    margin: 0;
                    position: relative;
                    &:hover {
                        .department-megamenu {
                            pointer-events: auto;
                            visibility: visible;
                            opacity: 1;
                            transform: translateX(0);
                            .department-submenu {
                                opacity: 1;
                                transform: translateX(0);
                            }
                            .featured-product {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }
                    &:last-child {
                        .nav-link {
                            border-bottom: none;
                        }
                    }
                }
            }
            .nav-link {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                padding: 13px 0;
                border-bottom: 1px solid #f3f3f3;
                position: relative;
                @media #{$smlg-device} {
                    font-size: 16px;
                    padding: 12px 0;    
                }
                .menu-icon {
                    margin-right: 14px;
                    position: relative;
                    top: -2px;
                    img {
                        width: 25px;
                        height: auto;
                    }
                }
                &.has-megamenu {
                    &:after {
                        content: "\f107";
                        font-family: var(--font-awesome);
                        font-weight: 400;
                        color: #c5c5c5;
                        font-size: 14px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        transition: var(--transition);
                    }
                }
                &:hover {
                    color: var(--color-heading);
                    &:after {
                        color: var(--color-primary);
                        transform: translateY(-50%) rotate(-90deg);
                    }
                }
            }
            &.open {
                left: 0;
            }
        }
        .department-megamenu {
            position: absolute;
            top: 0;
            left: 100%;
            width: 990px;
            z-index: 3;
            transform: translateX(10px);
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
            margin-left: 1px;
            @media only screen and (max-width: 1320px) {
                width: 870px; 
            }
            @media only screen and (max-width: 1199px) {
                position: initial;
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
                pointer-events: auto;
                width: auto;
                display: none;
                transition: initial;
            }
            .department-megamenu-wrap {
                background-color: var(--color-white);
                border-radius: 0 0 24px 0;
                box-shadow: 40px 40px 48px 0px rgba(36, 41, 47, 0.1);
                padding: 30px;
                display: flex;
                @media only screen and (max-width: 1320px) {
                    padding: 15px;
                }
                @media only screen and (max-width: 1199px) {
                    display: block;
                    padding: 0;
                    box-shadow: none;
                    padding: 20px 0;
                }
            }
            .department-submenu-wrap {
                flex: auto;
                padding: 30px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 20px;
                border-right: 2px solid #F6F7FB;
                @media only screen and (max-width: 1199px) {
                    grid-template-columns: repeat(1, 1fr);
                    padding: 0;
                    border: none;
                }
            }
            .department-submenu {
                opacity: 0;
                transform: translateX(10px);
                transition: all 0.3s ease-in-out;
                @media only screen and (max-width: 1199px) {
                    opacity: 1;
                    transform: translateX(0);
                }
                &:nth-child(1n) {
                    transition-delay: 0.1s;
                }       
                &:nth-child(2n) {
                    transition-delay: 0.2s;
                }       
                &:nth-child(3n) {
                    transition-delay: 0.3s;
                }
                .submenu-heading {
                    font-size: 16px;
                    color: var(--color-black);
                    margin-bottom: 12px;
                    @media #{$smlg-device} {
                        font-size: 16px;
                    }
                }
                ul {
                    margin-bottom: 30px;
                    @extend %liststyle;
                    li {
                        a {
                            font-size: 14px;
                            font-weight: 500;
                            color: var(--color-body);
                            padding: 10px 0;
                            &:hover {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
            .featured-product {
                padding: 40px 30px 40px 55px;
                max-width: 40%;
                opacity: 0;
                transform: translateY(10px);
                transition: all 0.3s ease-in-out;
                transition-delay: 0.4s;
                @media only screen and (max-width: 1199px) {
                    max-width: 100%;
                    opacity: 1;
                    transform: translateY(0);
                    padding: 0;

                }
                .featured-heading {
                    font-size: 16px;
                    color: var(--color-black);
                    margin-bottom: 12px;
                }
                .product-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 15px;
                    .item-product {
                        &:nth-child(-n +2) {
                            grid-column: span 2;
                        }
                        a {
                            overflow: hidden;
                            display: block;
                            border-radius: 8px;
                            img {
                                border-radius: 8px;
                                transition: 0.4s ease-in-out;
                            }
                            &:hover {
                                img {
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
                .axil-btn {
                    margin-top: 15px;
                    display: block;
                    text-align: center;
                }
            }
        } 
    }

    .mainmenu {
        justify-content: flex-start;
        >li {
            @media #{$smlg-device} {
                margin: 0 20px;
            }
            &:last-child {
                margin-right: 0;
            }
            >a {
                line-height: 60px;
                height: 60px;
                &:before {
                    bottom: 18px;
                }
            }
        }
    }
}


/*-----------------------
    Mobile Menu
-------------------------*/
.header-main-nav {
    .mainmenu-nav {  
        @media only screen and (max-width: 991px) {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            right: -250px;
            width: 250px;
            background-color: var(--color-white);
            z-index: 100; 
            transition: all 0.3s ease-in-out;
            padding: 20px 30px 10px;
            visibility: hidden;
            opacity: 0;
            .mainmenu {
                display: block;
                height: calc(100vh - 85px);
                overflow-y: auto;
                margin: 0;
                >li {
                    margin: 10px 0 !important;
                    transform: translateY(20px);
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    >a {
                        color: var(--color-body);
                        line-height: var(--line-height-b2) !important;
                        height: auto !important;
                        padding: 5px 0;
                        display: inline-block;
                        &::before {
                           display: none;
                        }
                    }
                    &.menu-item-has-children {
                        a {
                            margin: 0;
                            &::after {
                                right: -18px;
                                top: 4px;
                                color: var(--color-body);
                            }
                        }
                        .axil-submenu {
                            display: none;
                            position: static;
                            transform: scaleY(1);
                            visibility: visible;
                            opacity: 1;
                            min-width: auto;
                            box-shadow: none;
                            padding: 0;
                            transition: initial;
                            li {
                                a {
                                    padding: 5px 10px;
                                    &:after {
                                        display: none;
                                    }
                                    &:hover {
                                        color: var(--color-primary);
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.open {
        .mainmenu-nav {
            right: 0;
            visibility: visible;
            opacity: 1;
            .mainmenu {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    &:nth-child(1n) {
                        transition-delay: 0.3s;
                    }
                    &:nth-child(2n) {
                        transition-delay: 0.4s;
                    }
                    &:nth-child(3n) {
                        transition-delay: 0.5s;
                    }
                    &:nth-child(4n) {
                        transition-delay: 0.6s;
                    }
                    &:nth-child(5n) {
                        transition-delay: 0.7s;
                    }
                    &:nth-child(6n) {
                        transition-delay: 0.8s;
                    }
                    &:nth-child(7n) {
                        transition-delay: 0.9s;
                    }
                    &:nth-child(8n) {
                        transition-delay: 1s;
                    }
                }
            }
        }
    }
}

.mobile-close-btn {
    background-color: transparent;
    position: absolute;
    top: 19px;
    right: 15px;
    height: 35px;
    width: 35px;
    background-color: var(--color-lighter);
    border-radius: 40px;
    color: var(--color-dark);
    font-size: 12px;
    @media only screen and (min-width: 992px) {
        display: none;   
    }
    &:hover {
        background-color: var(--color-primary);
        color: var(--color-white);
    }
}

.mobile-nav-brand {
    margin-bottom: 30px;
    img {
        max-height: 35px;
    }
    @media only screen and (min-width: 992px) {
        display: none;   
    }
}

.axil-mobile-toggle {
    margin-left: 30px;
    @media only screen and (min-width: 992px) {
        display: none;
    }
    @media #{$large-mobile} {
        margin-left: 18px;
    }
    .menu-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        background-color: transparent;
        position: relative;
        z-index: 1;
        color: var(--color-heading);
        font-size: 23px;
        @media #{$small-mobile} {
            font-size: 22px;
        }
        i {
            display: inline-block;
            line-height: 0;
        }
        &:after {
            content: "";
            height: 40px;
            width: 40px;
            background: var(--color-secondary);
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            transform: scale(0);
            transition: var(--transition);
            @media #{$large-mobile} {
                height: 35px;
                width: 35px;
            }
        }
        &:hover {
            color: var(--color-white);
            &:after {
                transform: scaleX(1);
            }
        }
    }
}

.header-mobile-brand {
    img {
        width: 150px;
    }
}

