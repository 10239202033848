/*-------------------------
Service
--------------------------*/
.service-box {
    background-color: var(--color-white);
    border: 1px solid #f1f1f1;
    padding: 50px 30px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 6px;
    position: relative;
    transition: all .3s ease-in-out;
    @media only screen and (min-width: 1400px) {
        padding: 50px 40px;
    }
    @media #{$smlg-device} {
        padding: 40px 30px;
    }
    @media #{$sm-layout} {
        padding: 40px;
    }
    &:before {
        content: "";
        height: 100%;
        width: 90%;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease-in-out;
    }
    .icon {
        margin-bottom: 20px;
        img {
            max-height: 60px;
        }
    }
    .title {
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: var(--shadow-dark);
        &:before {
            visibility: visible;
            opacity: 1;
            bottom: -12px;
        }
    }
    &.service-style-2 {
        display: flex;
        border: none;
        padding: 0;
        text-align: left;
        background-color: transparent;
        &:before {
            display: none;
        }
        .icon {
            margin-right: 20px;
            margin-bottom: 0;
            max-width: 45px;
            margin-top: 6px;
        }
        .content {
            flex: 1;
            .title {
                line-height: var(--line-height-b1);
            }
        }
        &:hover {
            box-shadow: none;
        }
    }
    &.service-style-3 {
        background-color: #F6F7FB;
        border: none;
        border-radius: 8px;
        padding: 60px 30px 40px;
        &:before {
            display: none;
        }
        .icon {
            position: relative;
            z-index: 1;
            margin-bottom: 40px;
            &:before {
                content: "";
                width: 99px;
                height: 99px;
                background-color: var(--color-white);
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: -1;
            }
            i {
                font-size: 50px;
                color: var(--color-primary);
            }
        }
        .content {
            .title {
                margin-bottom: 4px;
                font-weight: 700;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}


// How To Sell
.how-to-sell {
    padding: 30px 20px;
    .title {
        font-size: 18px;
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
    }
}